import React from 'react';

import { ButtonLink } from 'components/Shared/Inputs/Button';

export interface PageMessageProps {
  icon?: string;
  itemType: string;
  link?: string;
  linkTitle?: string;
  message: string;
}

function NoItemsAvailableMessage({ icon, itemType, link, linkTitle, message }: PageMessageProps) {
  return (
    <div className="h-flex-display l-flex--column l-flex--center ">
      <img className="c-no-message-icon" src={icon} alt={`no ${itemType} available icon`} />
      <div className="h-flex-display l-flex--column l-flex--center  c-no-message-container c-typography--justify-center">
        <div className="h-with-small-margin-bottom">
          <h3 className="c-item-type-text h-inline-block-display">
            No <span className="c-highlighted-text h-inline-block-display">{itemType}</span> available
          </h3>
          <p className="">{message}</p>
        </div>
        <ButtonLink href={link} color="secondary">
          {linkTitle}
        </ButtonLink>
      </div>
    </div>
  );
}

export { NoItemsAvailableMessage };
