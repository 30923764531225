import React, { ReactNode } from 'react';

export interface AsideTextProps {
  children: ReactNode;
  title: string;
}

function AsideText({ title, children }: AsideTextProps) {
  return (
    <aside className="c-aside-text l-full-width h-border h-background--color-white">
      <header className="c-aside-text__header l-full-width h-background--color-brand h-with-padding">
        <h3>{title}</h3>
      </header>
      <div className="h-with-padding">{children}</div>
    </aside>
  );
}

export { AsideText };
