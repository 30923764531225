import { LoadingIcon } from 'components/Spinners';
import { Popup } from '../Popup';
import { ConfirmDialogProps } from './ConfirmDialog.interface';
import { Button } from 'components/Shared/Inputs';

export const ConfirmDialog = ({
  description,
  onCancelled,
  onConfirmed,
  title,
  isLoading,
  isCompleted,
  onCompleted,
  confirmationText,
}: ConfirmDialogProps) => {
  return (
    <Popup>
      <>
        {isLoading && (
          <>
            <LoadingIcon isLoading={true} />
            <div className="h-flex-display l-flex--justify-center m-t-30">
              <p className="m-b-30">Please wait...</p>
            </div>
          </>
        )}
        {isCompleted && (
          <>
            <p className="m-b-30">{confirmationText}</p>
            <div className="c-popup-footer-controls">
              <Button onClick={() => onCompleted()} filled color="primary">
                Confirm
              </Button>
            </div>
          </>
        )}
        {!isCompleted && !isLoading && (
          <>
            <p className="m-b-30">{description}</p>
            <div className="c-popup-footer-controls">
              <Button
                onClick={() => {
                  onCancelled();
                }}
                color="secondary"
              >
                Cancel
              </Button>
              <Button onClick={onConfirmed} filled color="primary">
                Confirm
              </Button>
            </div>
          </>
        )}
      </>
    </Popup>
  );
};
