import React from 'react';

import cx from 'classnames';

const Tabset = ({ additionalClassNames, children, onClickTab, tabOptions, selectedTab }) => {
  return (
    <div className={cx('c-tabset', additionalClassNames)}>
      {tabOptions.map(({ name, tabName }, index: number) => (
        <>
          <input
            className="c-tabset__input"
            type="radio"
            aria-hidden="true"
            value={name}
            onClick={onClickTab}
            name="tabset"
            id={`tab${index + 1}`}
            checked
          />
          <label
            className={cx('c-tabset__label', {
              'c-tabset__label--selected': selectedTab === name,
            })}
            htmlFor={`tab${index + 1}`}
          >
            {tabName}
          </label>
        </>
      ))}
      <div className="tab-panels">{children}</div>
    </div>
  );
};

export { Tabset };
