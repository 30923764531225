export interface ApiErrorsProps {
  errorMessages: string[];
}

function ApiErrors({ errorMessages = [] }: ApiErrorsProps) {
  if (!errorMessages.length) {
    return null;
  }

  return (
    <div className="h-border h-border--color-brand-tertiary h-color--brand-tertiary h-font-weight--600 h-with-y-margin h-with-padding">
      <ul>
        {errorMessages.map((errorMessage, errorMessageIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={`error-message-${errorMessageIndex}`}>{errorMessage}</li>
        ))}
      </ul>
    </div>
  );
}

export { ApiErrors };
