import React from 'react';

import { ColorType, contrastMap } from 'consts/colors';

import { Icon } from 'components/Shared/UI/Icon';
import { IconTypes } from 'components/Shared/UI/Icons/Icons';
import { Link } from 'components/Shared/UI/Link';

export interface TileProps {
  as?: string;
  backgroundColor: ColorType;
  hasAttention?: boolean;
  href?: string;
  iconId: IconTypes;
  isDisabled?: boolean;
  id?: string;
  onClick?: () => void;
  orphaned?: boolean;
  text?: string;
  title: string;
  tooltip?: string;
}
export function Tile({
  backgroundColor,
  iconId,
  id,
  title,
  orphaned,
  href,
  tooltip,
  as,
  onClick,
  text = 'More',
  hasAttention = false,
  isDisabled = false,
}: TileProps) {
  const Component = orphaned ? 'div' : 'li';
  const InternalComponent = href ? Link : 'div';
  return (
    <Component
      id={id}
      className={`c-tile h-background--color-${backgroundColor}  ${onClick || href ? `c-tile-animated` : ''} u-foreground ${
        isDisabled ? 'disabled' : ''
      }`}
    >
      <InternalComponent isDisabled={isDisabled} onClick={onClick} className="l-square-container h-no-top" href={href} as={as}>
        <div
          title={tooltip}
          className="l-flex l-flex--column l-flex--center l-square-container__content l-square-container__content--centered l-full-width h-with-padding"
        >
          {hasAttention && (
            <span
              style={{
                top: '0rem',
                right: '2rem',
              }}
              className="l-position--absolute ring-container"
            >
              <span className="ringring" />
              <span className="circle" />
            </span>
          )}
          <Icon id={iconId} size="3xl" color={contrastMap[backgroundColor] === 'light' ? 'primary' : 'white'} />
          <p
            className={`h-with-y-margin c-typography--text-center l-full-width h5 c-typography--color-${
              contrastMap[backgroundColor] === 'light' ? 'brand' : 'white'
            } c-typography--fontsize-small`}
          >
            {title}
          </p>
          {(href || onClick) && (
            <div className="c-tile__action l-flex l-flex--center">
              <span className="l-flex__item--row">{text} </span>
              <Icon className="l-flex__item--row" id="arrow" rotate="270" size="sm" />
            </div>
          )}
        </div>
      </InternalComponent>
    </Component>
  );
}
