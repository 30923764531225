import { useState } from 'react';

import { gql } from '@apollo/client';
import { useQuery } from 'utils/hooks/useQuery';

const MULTIPLE_SNIPPETS = gql`
  query MULTIPLE_SNIPPETS($snippetIds: [Int]!) {
    contentSnippet {
      getSnippets(snippetIds: $snippetIds) {
        ... on ContentSnippetCodeType {
          id
          location {
            contentType
            s3Key
            presignedUrl
          }
        }
      }
    }
  }
`;

interface EkardoSnippetProps {
  snippetId: number;
}

export function EkardoSnippet({ snippetId }: EkardoSnippetProps) {
  const [content, setContent] = useState('');

  const { data } = useQuery(MULTIPLE_SNIPPETS, {
    variables: {
      snippetIds: [snippetId],
    },
  });

  if (data) {
    const payload = data?.contentSnippet?.getSnippets;
    const url = payload[0]?.location?.presignedUrl;

    fetch(url)
      .then((res) => res.text())
      .then((markup) => setContent(markup));
  }

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: content,
      }}
    />
  );
}
