import React from 'react';

export interface FormallyCircleProps {
  className: string;
}

function FormallyCircle({ className }: FormallyCircleProps) {
  return (
    <div className={`c-formally-circle ${className}`}>
      <div className="l-circle-container h-background--color-brand">
        <div className="c-formally-circle__content l-circle-container__content l-square-container__content--centered l-flex l-flex--center l-flex--column">
          <p className="c-typography--bold c-typography--text-center">Formerly known as</p>
          <img src="/images/logo-old.png" alt="" />
        </div>
      </div>
    </div>
  );
}

export { FormallyCircle };
