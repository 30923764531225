import React, { ReactNode } from 'react';

export interface AccordionListProps {
  children: ReactNode;
  className?: string;
}

export function AccordionList({ children, className }: AccordionListProps) {
  return (
    <ul aria-label="Accordion Control Group Buttons" className={`c-accordion-list ${className}`}>
      {children}
    </ul>
  );
}
