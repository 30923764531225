import { useEffect, useState } from 'react';

import Joyride, { CallBackProps, STATUS } from 'react-joyride';
import { useHasSeenSpotlight } from 'utils/hooks/useHasSeenSpotlight';

import { spotLightConstants } from './Spotlight.constants';
import { ISpotlight, SpotlightProps } from './Spotlight.interfaces';

const Spotlight = ({
  disableOverlayClose,
  disableCloseOnEsc,
  hideCloseButton,
  spotlightItem,
  onSpotLightData,
  trigger,
}: SpotlightProps) => {
  const { hasSeenSpotlight = true, performHasSeenSpotlight } = useHasSeenSpotlight();
  useEffect(() => {
    if (!hasSeenSpotlight) {
      setTimeout(() => {
        handleSpotlightStart();
      }, spotLightDisplayTimeoutInMilliseconds);
    }
  }, [hasSeenSpotlight]);

  useEffect(() => {
    if (trigger) {
      handleSpotlightStart();
    }
  }, [trigger]);

  useEffect(() => {
    performHasSeenSpotlight(window.location.href);
  }, []);

  const [spotlightTour, setSpotlightTour] = useState<ISpotlight>(spotlightItem);

  const { spotLightDisplayTimeoutInMilliseconds } = spotLightConstants;

  const handleSpotlightCallback = (data: CallBackProps) => {
    const { status } = data;
    onSpotLightData?.(data);

    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setSpotlightTour({
        ...spotlightTour,
        run: false,
      });
    }
  };

  const handleSpotlightStart = () => {
    setSpotlightTour({
      ...spotlightTour,
      run: true,
    });
  };

  return (
    <div>
      <Joyride
        callback={handleSpotlightCallback}
        continuous={true}
        disableCloseOnEsc={disableCloseOnEsc}
        disableOverlayClose={disableOverlayClose}
        hideCloseButton={hideCloseButton}
        run={spotlightTour.run}
        locale={{ last: 'Done' }}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        styles={{
          options: {
            primaryColor: '#5a3bd9',
            zIndex: 1000,
          },
        }}
        steps={spotlightTour.steps}
      />
    </div>
  );
};

export { Spotlight };
