export type ColorType =
  | 'brand'
  | 'brand-secondary'
  | 'brand-tertiary'
  | 'blue'
  | 'green'
  | 'grey-300'
  | 'grey'
  | 'grey-700'
  | 'white'
  | 'black';

export const colorMap = {
  brand: '#1e0059',
  'brand-secondary': '#5a37db',
  'brand-tertiary': '#e85d5d',
  blue: '#160043',
  green: '#61e2b1',
  'grey-300': '#efeee6',
  grey: '#e0ded3',
  'grey-700': '#5f5f58',
  white: '#fff',
  black: '#000',
};

export const contrastMap = {
  brand: 'dark',
  'brand-secondary': 'dark',
  'brand-tertiary': 'dark',
  blue: 'dark',
  green: 'light',
  'grey-300': 'light',
  grey: 'light',
  'grey-700': 'dark',
  white: 'light',
  black: 'dark',
};
