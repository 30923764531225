import React from 'react';

import cx from 'classnames';

export interface ProgressCircleProps {
  className?: string;
  color?: 'default' | 'primary' | 'secondary' | 'tertiary';
  describedBy?: string;
  description?: string;
  percent: number;
  showPercent?: boolean;
  showTickOnComplete?: boolean;
  title?: string;
}

export function ProgressCircle({
  className,
  color = 'default',
  percent,
  showPercent,
  showTickOnComplete,
  title,
  description,
  describedBy,
}: ProgressCircleProps) {
  const size = 100;
  const strokeWidth = 20;
  const radus = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radus;
  const displayPercentage = circumference - circumference * (percent / 100);

  const isComplete = percent >= 100;
  const showTick = showTickOnComplete && isComplete && !showPercent;

  let labelledby;

  if (describedBy == null && title) {
    labelledby = `title-${title} `;
    if (description) labelledby = `desc-${title} `;
  }

  return (
    <svg
      className={`c-progress-circle ${className}`}
      viewBox="0 0 100 100"
      role="img"
      aria-labelledby={labelledby}
      aria-describedby={describedBy}
    >
      {title && <title id={`title-${title}`}>{title}</title>}
      {description && <desc id={`desc-${title}`}>{description}</desc>}

      <g className="c-progress-circle--rotate">
        <circle className="c-progress-circle__circle-background" cx="50" cy="50" r={radus} strokeWidth={strokeWidth} />
        <circle
          className={cx('c-progress-circle__circle-progress', `c-progress-circle__circle-progress--${color}`, {
            'c-progress-circle__circle-progress--full': isComplete,
            'c-progress-circle__circle-progress--with-tick': showTick,
          })}
          cx="50"
          cy="50"
          r={radus}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={displayPercentage}
          strokeWidth={strokeWidth}
        />
      </g>
      {showPercent && (
        <text className="c-progress-circle__text" x="50%" y="50%" dominantBaseline="middle" textAnchor="middle">
          {percent}%
        </text>
      )}
      {showTick && <use className="c-progress-circle__tick" xlinkHref="#tick" />}
    </svg>
  );
}
