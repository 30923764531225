import { useState } from 'react';

import cx from 'classnames';

import { Button } from 'components/Shared/Inputs/Button';
import { Information } from 'components/Shared/UI/Information';

interface AlertProps {
  message: string;
}

const Alert: React.FC<AlertProps> = ({ message }) => {
  const [isVisible, setIsVisible] = useState<boolean>(true);

  const handleClick = () => {
    setIsVisible(false);
  };

  return (
    <div
      className={cx('c-alert', {
        'c-alert--is-visible': isVisible,
        'h-hide': !isVisible,
      })}
      role="alert"
    >
      <Information ariaLabel="Alert: " message={message} />
      <Button onClick={handleClick} className="c-alert___close" icon="cross" iconPosition="right" />
    </div>
  );
};

export { Alert };
